import "./About.css";
// import thanuda_lakshan_kumara from "../../media/photos/people/thanuda_lakshan_kumara.jpg";
import dinith_rukshan_kumara from "../../media/photos/people/dinith_rukshan_kumara.jpg";
import who_we_are from "../../media/photos/who_we_are.jpg";
import vision from "../../media/photos/vision.jpg";
import leadership from "../../media/photos/leadership.jpg";
import mission from "../../media/photos/mission.jpg";
import our_values from "../../media/photos/our_values.jpg";
import story from "../../media/photos/story.jpg";

export default function About() {
  return (
    <>
      <div className="about-main-container">
        <h1 className="about-title-text">About Us</h1>

        <div className="about-item-main-container">
          <div className="about-item">
            <img
              src={who_we_are}
              alt="who we are"
              className="about-item-image"
            />
            <div className="about-item-content-container">
              <h2 className="about-item-title">Who We Are</h2>
              <div className="about-item-body">
                We are Apcey Technologies, a startup IT company in Sri Lanka. We
                build awesome software masterpieces.
              </div>
            </div>
          </div>

          <div className="about-item">
            <img src={vision} alt="Vision" className="about-item-image" />
            <div className="about-item-content-container">
              <h2 className="about-item-title">Our Vision</h2>
              <div className="about-item-body">
                To create the most compelling tech company of the Sri Lanka and
                become a well reputed international tech company.
              </div>
            </div>
          </div>

          <div className="about-item">
            <img src={mission} alt="Mission" className="about-item-image" />
            <div className="about-item-content-container">
              <h2 className="about-item-title">Our Mission</h2>
              <div className="about-item-body">
                Transform our creative and innovative ideas in to software
                solutions and develop unique, smart, responsive, high quality
                software solutions.
              </div>
            </div>
          </div>

          <div className="about-item">
            <img
              src={our_values}
              alt="Our Values"
              className="about-item-image"
            />
            <div className="about-item-content-container">
              <h2 className="about-item-title">Our Values</h2>
              <div className="about-item-body">
                Inclusivity, community, accountability, agility and integrity
                are the core values that define the our culture and people.
              </div>
            </div>
          </div>

          <div className="about-item">
            <img src={story} alt="Story" className="about-item-image" />
            <div className="about-item-content-container">
              <h2 className="about-item-title">Our Story</h2>
              <div className="about-item-body">
                Founded in 2022, we took a challenge in Information Technology
                sector and helped create a global Technology and future.
              </div>
            </div>
          </div>

          <div className="about-item">
            <img
              src={leadership}
              alt="Leadership"
              className="about-item-image"
            />
            <div className="about-item-content-container">
              <h2 className="about-item-title">Leadership</h2>
              <div className="about-item-body">
                Strong leadership, passion and a clear vision has shaped the
                company into a leader in Sri Lankan IT Industry.
              </div>
            </div>
          </div>
        </div>

        <h1 className="about-title-text">Message from Owner</h1>

        <div class="about-board-of-directors-container">
          <div
            class="about-person-item"
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <div class="about-img-item">
              <img
                src={dinith_rukshan_kumara}
                alt="Dinith Rukshan Kumara"
                className="about-director-img"
              />
            </div>
            <h2 className="about-person-item-name">Dinith Rukshan Kumara</h2>
            <h3 className="about-person-item-title">CEO | CTO</h3>
            <p className="about-person-item-mesage">
              "We provide highest standard of quality. Build most sophisticated
              and up-to-date software solutions for small to enterprise
              organisations. Every systems we designed, we build is always
              driven by the desire for quality. Ensure softwares and services
              smarter, smoother and efficient. Pull together right people,
              processes and expertise to take services from good to the
              greatest."
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
