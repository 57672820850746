import "./Services.css";
import mobile_app_development from "../../media/icons/services/mobile_app_development.svg";
import web_development from "../../media/icons/services/web_development.svg";
import api_development from "../../media/icons/services/api_development.svg";
import ui_ux from "../../media/icons/services/ui_ux.svg";
import artificial_intelligence_and_machine_learning from "../../media/icons/services/artificial_intelligence_and_machine_learning.svg";
import digital_marketing_and_seo from "../../media/icons/services/digital_marketing_and_seo.svg";
import industrial_iot from "../../media/icons/services/industrial_iot.svg";
import graphic_design from "../../media/icons/services/graphic_design.svg";
import blockchain from "../../media/icons/services/blockchain.png";

export default function Services() {
  return (
    <>
      <div className="service-main-container">
        <div className="service-info-container">
          <h1
            className="service-title-text"
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            Services
          </h1>
          <p
            className="service-description-text"
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            We have a talented team providing the highest quality. This is our
            passion. We are really good at it.
          </p>
        </div>
        
        <div className="service-items-container">
          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h1 className="service-item-title">Mobile App Development</h1>
            <div class="service-round-div">
              <img
                src={mobile_app_development}
                class="service-circle-img"
                alt="Mobile Application Development"
              />
            </div>
            <p className="service-item-description">
              World has a tend to go mobile. We develop very responsive and high
              quality Android and iOS mobile apps. We do both native and hybrid
              apps.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h1 className="service-item-title">Web Development</h1>
            <div class="service-round-div">
              <img
                src={web_development}
                class="service-circle-img"
                alt="Mobile Application Development"
              />
            </div>
            <p className="service-item-description">
              Build your dream website from our experts. We can develop modern &
              beautiful web site to your business. Grow & take your business to
              the next level.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h1 className="service-item-title">Back-End API Development</h1>
            <div class="service-round-div">
              <img
                src={api_development}
                class="service-circle-img"
                alt="Mobile Application Development"
              />
            </div>
            <p className="service-item-description">
              Build your enterprise applications APIs secure, fast, scalable &
              optimized. We use industry best practises & latest technologies to
              build your APIs.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h1 className="service-item-title">
              User Interface & User Experience (UI/UX)
            </h1>
            <div class="service-round-div">
              <img
                src={ui_ux}
                class="service-circle-img"
                alt="Mobile Application Development"
              />
            </div>
            <p className="service-item-description">
              Attract more customers to your bussines with our UI/UX
              professionals acctrative & user friendly designes. We follow
              industry best practices on our designs.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h1 className="service-item-title">
              Artificial Intelligence & Machine Learning (AI/ML)
            </h1>
            <div class="service-round-div">
              <img
                src={artificial_intelligence_and_machine_learning}
                class="service-circle-img"
                alt="Mobile Application Development"
              />
            </div>
            <p className="service-item-description">
              AI & ML are the revolutionary technologies of the modern day.
              Powerup your systems & business with these emerging technologies &
              defeat your compititors.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h1 className="service-item-title">
              Digital Marketing & Search Engine Optimization (SEO)
            </h1>
            <div class="service-round-div">
              <img
                src={digital_marketing_and_seo}
                class="service-circle-img"
                alt="Mobile Application Development"
              />
            </div>
            <p className="service-item-description">
              Digital Marketing & SEO trends can help & guide you to increase
              user traffic, your brand recognition and revenue. We follow newest
              SEO trends & strategies.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h1 className="service-item-title">Internet of Things (IoT)</h1>
            <div class="service-round-div">
              <img
                src={industrial_iot}
                class="service-circle-img"
                alt="Mobile Application Development"
              />
            </div>
            <p className="service-item-description">
              Improve process and quality with leading IoT technologies.
              Increase productivity, safety and quality with advance solutions.
              Maximize IT Investment. Scalable Solution. Seamless Integration.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h1 className="service-item-title">Graphic Design</h1>
            <div class="service-round-div">
              <img
                src={graphic_design}
                class="service-circle-img"
                alt="Mobile Application Development"
              />
            </div>
            <p className="service-item-description">
              We create innovative, on-trend designs, our work is more likely to
              be recognized and work effectively for clients. Our great
              designers always creative & up to date with latest & upcoming
              trends.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h1 className="service-item-title">Blockchain Development</h1>
            <div class="service-round-div">
              <img
                src={blockchain}
                class="service-circle-img"
                alt="Blockchain Development"
              />
            </div>
            <p className="service-item-description">
              World is step in to the web 3.0 with Block chain technology and
              decentralization. We develop decentralized applications (dapps).
              Building, maintaining and designing unique blockchain applications
              and systems.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
