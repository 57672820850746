import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import "./Navbar.css";
import apcey_logo from "../../media/apcey_logo.svg";
import menu_icon from "../../media/menu_icon.svg";
import close_icon from "../../media/close_icon.svg";
import Footer from "../Footer/Footer";

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div>
      <nav>
        <div className="nav-company-name-container">
          <img className="nav-company-logo" src={apcey_logo} alt="logo"></img>
          <h1 className="nav-company-title">APCEY</h1>
        </div>

        {(toggleMenu || screenWidth > 600) && (
          <ul className="nav-list">
            <li className="nav-items">
              <Link to="/" className="nav-link">
                <p className="nav-link-p">Home</p>
              </Link>
            </li>
            <li className="nav-items">
              <Link to="/services" className="nav-link">
                <p className="nav-link-p">Services</p>
              </Link>
            </li>
            <li className="nav-items">
              <Link to="/solutions" className="nav-link">
                <p className="nav-link-p">Solutions</p>
              </Link>
            </li>
            <li className="nav-items">
              <Link to="/method" className="nav-link">
                <p className="nav-link-p">Method</p>
              </Link>
            </li>
            <li className="nav-items">
              <Link to="/careers" className="nav-link">
                <p className="nav-link-p">Careers</p>
              </Link>
            </li>
            <li className="nav-items">
              <Link to="/about" className="nav-link">
                <p className="nav-link-p">About</p>
              </Link>
            </li>
            <li className="nav-items">
              <Link to="/contact" className="nav-link">
                <p className="nav-link-p">Contact</p>
              </Link>
            </li>
          </ul>
        )}

        <img
          className="nav-toggle-btn"
          onClick={toggleNav}
          src={toggleMenu ? close_icon : menu_icon}
          alt="menu_icon"
        ></img>
      </nav>
      <div className="nav-outlet">
        <Outlet></Outlet>
      </div>

      <Footer></Footer>
    </div>
  );
}
