import "./Solutions.css";
import business_websites from "../../media/icons/solutions/business_websites.svg";
import crm_systems from "../../media/icons/solutions/crm_systems.svg";
import education_platforms from "../../media/icons/solutions/education_platforms.svg";
import erp_systems from "../../media/icons/solutions/erp_systems.svg";
import inventory_management_systems from "../../media/icons/solutions/inventory_management_systems.svg";
import online_stores from "../../media/icons/solutions/online_stores.svg";
import pos_system from "../../media/icons/solutions/pos_system.svg";
import taxi_app_solution from "../../media/icons/solutions/taxi_app_solution.svg";
import live_stream from "../../media/icons/solutions/live_stream.png";

export default function Solutions() {
  return (
    <>
      <div className="service-main-container">
        <div className="service-info-container">
          <h1
            className="service-title-text"
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            Solutions
          </h1>
          <p
            className="service-description-text"
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            We have perfectly developed battle-tested systems for your needs.
          </p>
        </div>

        <div className="service-items-container">
          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <h1 className="service-item-title">Business Websites</h1>
            <div class="service-round-div">
              <img
                src={business_websites}
                class="service-circle-img"
                alt="Business Websites"
              />
            </div>
            <p className="service-item-description">
              Don’t have a website for your business? you are losing customers &
              many opportunities. Now website is a crucial for any business in
              the world. Promote your business online & expand your bussiness.
              We can build your dream website for your businesss.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <h1 className="service-item-title">Point Of Sales Systems</h1>
            <div class="service-round-div">
              <img
                src={pos_system}
                class="service-circle-img"
                alt="Point Of Sales Systems"
              />
            </div>
            <p className="service-item-description">
              Manage your company orders easily without any paper work and
              eliminate the manual entry reporting errors. Our advanced features
              & analytics ensure increase in your profit and business
              efficiency. Having an effective POS system always give you a
              positive effect.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <h1 className="service-item-title">Taxi App Solutions</h1>
            <div class="service-round-div">
              <img
                src={taxi_app_solution}
                class="service-circle-img"
                alt="Taxi App Solutions"
              />
            </div>
            <p className="service-item-description">
              Fully scalable and smart taxi dispatch and booking system. We have
              the most accurate offline meter and GPS based distance calculate
              methods developed using artificial intelligence and machine
              learning techniques.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <h1 className="service-item-title">CRM Systems</h1>
            <div class="service-round-div">
              <img
                src={crm_systems}
                class="service-circle-img"
                alt="CRM Systems"
              />
            </div>
            <p className="service-item-description">
              Customer Relationship Management is the key to the success.
              Unhappy customers spread the message 10 times more than happy
              customers. Our system guranteed your bussiness touches the heart
              of the customers. Grow your customer base is always better.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <h1 className="service-item-title">Learning management Systems</h1>
            <div class="service-round-div">
              <img
                src={education_platforms}
                class="service-circle-img"
                alt="Online Education Platform"
              />
            </div>
            <p className="service-item-description">
              Many researches show that the digital learning will replace
              printed textbooks within next few years. It’s only a matter of
              time. Its time to adopt new technologies for you learing. Manage
              the learing and education works by using our Learning management
              Systems.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <h1 className="service-item-title">ERP Systems</h1>
            <div class="service-round-div">
              <img
                src={erp_systems}
                class="service-circle-img"
                alt="ERP Systems"
              />
            </div>
            <p className="service-item-description">
              Highly secured cloud based realtime update solution, which is
              applicable for any industry. Our system proved huge increase in
              many organizations efficiancy and effectiveness. We can gurantee
              the reduce time & cost for any business processes.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <h1 className="service-item-title">Inventory Management Systems</h1>
            <div class="service-round-div">
              <img
                src={inventory_management_systems}
                class="service-circle-img"
                alt="Inventory Management Systems"
              />
            </div>
            <p className="service-item-description">
              Track your goods throughout the entire supply chain. Manually
              managing inventory gets harder as your business scales. Take the
              advantage of the technology. We provide dedicated effective
              inventory management system for your specific needs.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <h1 className="service-item-title">Online Stores</h1>
            <div class="service-round-div">
              <img
                src={online_stores}
                class="service-circle-img"
                alt="Online Stores"
              />
            </div>
            <p className="service-item-description">
              Most people prefer online shopping over conventional shopping now.
              People like to purchase items online simply sitting in their
              homes. It is a world wide trend & rapidly growing. We can build
              best online store for your needs.
            </p>
          </div>

          <div
            className="service-item service-item-1"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <h1 className="service-item-title">Streaming Apps</h1>
            <div class="service-round-div">
              <img
                src={live_stream}
                class="service-circle-img"
                alt="streaming apps"
              />
            </div>
            <p className="service-item-description">
              Most tv and radio channels are now online streaming. If you have a
              tv, radio or live streaming channels, we can give you a complete
              solution with web, android and iOS apps customized for your
              channel. Attract more customers and promote more content and
              services by providing streaming apps.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
