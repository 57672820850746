import "./Home.css";
import hero_image from "../../media/photos/hero_image_2.jpg";
import chevron_down from "../../media/icons/chevron_down.svg";

export default function Home() {
  return (
    <>
      <div className="home-main-container">
        <div className="home-hero-main-container">
          {/* <div className="christmas-container">
            <div class="christmas-text">🎅</div>
          </div>
          <Snowfall /> */}
          <div className="home-hero-image-container">
            <img className="home-hero-image" src={hero_image} alt="hero" />
            <div className="home-hero-title-container">
              <p className="home-hero-title-text-1" data-aos="fade-right">
                Bring IT Together
              </p>
              <p className="home-hero-title-text-2" data-aos="fade-right">
                Agile. Adaptive. Authentic
              </p>
              <p className="home-hero-title-text-3" data-aos="fade-right">
                Apcey partners with organisations, teams and people to help them
                deliver faster and better.
              </p>
            </div>
          </div>
          <img
            src={chevron_down}
            alt="chevron down"
            className="home-hero-chevron"
          />
        </div>

        <div className="home-content-container">
          <div className="home-content-about-short">
            <p
              className="home-content-about-title-text"
              data-aos="zoom-in"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              Why Apcey?
            </p>
            <p
              className="home-content-about-message"
              data-aos="zoom-in"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              We help you extend business with our products and exceptional skills
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
