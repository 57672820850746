import "./Careers.css";
import team from "../../media/photos/team2.jpg";

export default function Careers() {
  return (
    <>
      <div className="careers-main-container">
        <div className="careers-dream-job-container">
          <div className="careers-dream-job-left-container">
            <div className="careers-dream-job-title-container">
              <p className="careers-dream-job-title">Ready for your</p>
              <pre className="careers-dream-job-title careers-dream-job-title-red">
                {" "}
                dream
              </pre>
              <pre className="careers-dream-job-title"> job?</pre>
            </div>

            <div className="careers-dream-job-description">
              Join our growing team today
            </div>
            <div className="careers-dream-job-button-container">
              <p className="careers-dream-job-button-text">View Open Jobs</p>
            </div>
          </div>
          <div className="careers-dream-job-right-container">
            <img
              src={team}
              alt="Careers team"
              className="careers-dream-job-img"
            />
          </div>
        </div>

        <div className="careers-how-we-work-container">
          <p className="careers-how-we-work-title">How we work</p>
          <div className="careers-how-we-work-item-container">
            <div className="careers-how-we-work-item">
              <p className="careers-how-we-work-description">
                “Apcey is a very unique company, we prioritise flexibility”
              </p>
            </div>

            <div className="careers-how-we-work-item">
              <p className="careers-how-we-work-description">
                “In our core values, working with respect, tolerance and
                transparency”
              </p>
            </div>

            <div className="careers-how-we-work-item">
              <p className="careers-how-we-work-description">
                “Taking care of our team members and making sure the success”
              </p>
            </div>

            <div className="careers-how-we-work-item">
              <p className="careers-how-we-work-description">
                “To help our teams collaborate better, we continuously iterating
                processes and ways of working”
              </p>
            </div>
          </div>
        </div>

        <div className="careers-need-more-info-container">
          <p className="careers-need-more-info-title">Need more information?</p>
          <a
            href="mailto:careers@apcey.com"
            className="careers-need-more-info-email"
          >
            careers@apcey.com
          </a>
        </div>
      </div>
    </>
  );
}
