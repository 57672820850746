import "./Method.css";
import work_image from "../../media/photos/work.jpg";

export default function Method() {
  return (
    <>
      <div className="method-main-container">
        <div className="method-our-method-container">
          <img
            className="method-our-method-img"
            src={work_image}
            alt="company how we work"
          />
          <div className="method-our-method-content-container">
            <div className="method-our-method-content-left-container">
              <h1 className="method-our-method-title">Our Method</h1>
              <p className="method-our-method-description">
                We follow agile software development methodologies. Industry
                proven best practices followed by the best IT companies
              </p>
              <p className="method-our-method-description">
                In our process we always focus on quality, testing and deliver
                good softwares on time with a good management and a special
                workflow
              </p>
            </div>
          </div>
        </div>

        <div className="method-what-you-get-container">
          <div className="method-what-you-get-title-container">
            <p className="method-what-you-get-title">
              What you get while working with us
            </p>
            <div className="method-what-you-get-title-underline"></div>
          </div>

          <div className="method-what-you-get-item-container">
            <div className="method-what-you-get-item">
              <span className="method-check-mark">&#10003;</span>
              <p>A certified team with experience in software development</p>
            </div>
            <div className="method-what-you-get-item">
              <span className="method-check-mark">&#10003;</span>
              <p>Good communications with our team</p>
            </div>
            <div className="method-what-you-get-item">
              <span className="method-check-mark">&#10003;</span>
              <p>Test new features in a safe environment using test servers</p>
            </div>
            <div className="method-what-you-get-item">
              <span className="method-check-mark">&#10003;</span>
              <p>
                Flexible resources planning. You can select resources for your
                budget
              </p>
            </div>
            <div className="method-what-you-get-item">
              <span className="method-check-mark">&#10003;</span>
              <p>Daily updates. You always know what's going on your project</p>
            </div>
            <div className="method-what-you-get-item">
              <span className="method-check-mark">&#10003;</span>
              <p>
                Access to the code (git repository), you can follow our work
                also in code
              </p>
            </div>
            <div className="method-what-you-get-item">
              <span className="method-check-mark">&#10003;</span>
              <p>
                Multiple communication channels: Slack, MeisterTask, Jira,
                Phone, Email
              </p>
            </div>
            <div className="method-what-you-get-item">
              <span className="method-check-mark">&#10003;</span>
              <p>
                QA specialist continuously check your system and keep it working
                and bug free
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
