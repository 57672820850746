import "./Contact.css";
import right_arrow from "../../media/icons/contact/right_arrow.svg";

export default function Contact() {
  return (
    <>
      <div className="contact-form-main-container">
        <div className="contact-form-info-container">
          <h1 className="contact-form-info-title-text">Get in touch</h1>
          <h1 className="contact-form-info-description-text">
            Let’s talk about how we can partner
          </h1>
          <p className="contact-form-info-email-title">
            Head | Sales Department
          </p>
          <a
            className="contact-form-info-email-text"
            href="mailto:sales@apcey.com"
          >
            Email : sales@apcey.com
          </a>
        </div>

        <div className="contact-form-block-container">
          <div className="contact-form-block-name-container">
            <h1 className="contact-form-block-input-title">First Name</h1>
            <h1 className="contact-form-block-input-title">Last Name</h1>
            <input
              type="text"
              className="contact-form-block-input"
              placeholder="First Name"
            />
            <input
              type="text"
              className="contact-form-block-input"
              placeholder="Last Name"
            />
          </div>

          <div className="contact-form-block-email-container">
            <h1 className="contact-form-block-input-title">Email</h1>
            <input
              type="text"
              className="contact-form-block-input"
              placeholder="Email"
            />
          </div>

          <div className="contact-form-block-message-container">
            <h1 className="contact-form-block-input-title">Message</h1>
            <textarea
              type="text"
              className="contact-form-block-message-text-area"
              placeholder="Message"
              cols="30"
              rows="4"
            />
          </div>

          <div className="contact-send-button-container">
            <p className="contact-send-button-text">Send</p>
            <img
              className="contact-send-button-img"
              src={right_arrow}
              alt="send message button icon"
            />
          </div>
        </div>
      </div>
    </>
  );
}
