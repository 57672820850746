import "./Footer.css";
import apcey_logo from "../../media/apcey_logo.svg";
import location_icon from "../../media/icons/contact/location.svg";
import telephone_icon from "../../media/icons/contact/telephone.svg";
import email_icon from "../../media/icons/contact/email.svg";

import facebook_icon from "../../media/icons/social_media/facebook.svg";
import instagram_icon from "../../media/icons/social_media/instagram.svg";
import linkedin_icon from "../../media/icons/social_media/linkedin.svg";
import twitter_icon from "../../media/icons/social_media/twitter.svg";
import whatsapp_icon from "../../media/icons/social_media/whatsapp.svg";

export default function Footer() {
  return (
    <div
      className="footer-main-container"
      data-aos="fade-in"
      data-aos-easing="linear"
      data-aos-duration="1000"
    >
      <div className="footer-company-title-container">
        <img
          className="footer-company-logo"
          src={apcey_logo}
          alt="company logo"
        ></img>
        <h1 className="footer-company-title-1">Apcey</h1>
        <h1 className="footer-company-title-2">Technologies</h1>
      </div>

      <div className="footer-content-container">
        <div className="footer-contact-info-container">
          <div className="footer-contact-item-container">
            <img
              src={telephone_icon}
              alt="company HR and finance department contact number"
              className="footer-contact-icon"
            />

            <a href="tel:+94-755-277-766">(+94) 763 992 896 | Office</a>
          </div>
          <div className="footer-contact-item-container">
            <img
              src={telephone_icon}
              alt="company HR and finance department contact number"
              className="footer-contact-icon"
            />

            <a href="tel:+94-755-277-766">(+94) 712 205 076 | Sales</a>
          </div>
          <div class="footer-contact-item-container">
            <img
              src={location_icon}
              alt="company location address"
              className="footer-contact-icon"
            />
            <p>320/B, Galahitiyawa, Ganemulla, Sri Lanka</p>
          </div>
        </div>
        <div className="footer-social-media-container">
          <img
            src={instagram_icon}
            alt="instagram icon"
            className="footer-social-media-icon"
          />
          <img
            src={facebook_icon}
            alt="facebook icon"
            className="footer-social-media-icon"
          />
          <img
            src={linkedin_icon}
            alt="linkedin icon"
            className="footer-social-media-icon"
          />
          <img
            src={twitter_icon}
            alt="twitter icon"
            className="footer-social-media-icon"
          />
          <img
            src={whatsapp_icon}
            alt="whatsapp icon"
            className="footer-social-media-icon"
          />
        </div>
        <div className="footer-email-container">
          <img
            src={email_icon}
            alt="email icon"
            className="footer-social-media-icon"
          />
          <a href="mailto:info@apcey.com">info@apcey.com</a>
        </div>
      </div>

      <p className="footer-copyright-text">
        © 2024 Apcey Technologies (Pvt) Ltd. All Rights Reserved.
      </p>
    </div>
  );
}
